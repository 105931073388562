@import '../../../../../semantic-ui/variables.less';

.partner-form {
	display: flex;
	flex-direction: column;
	padding: 20px 0;
	max-height: 602px;
	overflow: auto;

	&__header-text {
		width: 45%;
		font-family: @body-1-regular-family;
		font-size: 12px;
		font-weight: 400;
		margin-left: 302px;
		margin-top: 4px;
		line-height: 19px;

		&_block {
			width: 100%;
			display: block;
			text-align: center;
		}
	}

	&__row {
		display: flex;
		align-items: center;
		margin-bottom: 14px;

		&:first-child,
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__text {
		width: 40%;
		max-width: 292px;
		font-family: @body-1-regular-family;
		font-size: 13px;
		font-weight: 400;
		padding: 6px 10px;

		&_right {
			text-align: right;
		}

		&_bold {
			font-weight: bolder;
		}
	}

	&__field {
		width: 60%;
		max-width: 437px;
		padding: 0 10px;
	}
}
