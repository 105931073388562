@import '../../../semantic-ui/variables.less';

.accept-terms {
	display: flex;
	padding: 50px;
	gap: 40px;
	justify-content: center;

	&__content {
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 25px;
	}

	&__title {
		font-family: @body-1-regular-family;
		font-weight: 700;
		font-size: 28px;
		line-height: 35px;

		&_primary {
			color: @primary-500;
			text-align: center;
		}

		&_secondary {
			color: @secondary-500;
			text-align: center;
		}
	}

	&__text {
		font-family: @body-1-regular-family;
		font-weight: 400;
		font-size: 15px;
		line-height: 25px;
		max-width: 600px;
	}

	&__section-text-documents {
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
	}

	&__section-row {
		display: flex;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
	}

	&__section-text {
		padding-right: 10px;

		&-documents {
			text-decoration: underline;
		}
	}

	&__section-field {
		padding: 0 10px;
	}

	&__button {
		width: 330px;
		padding: 25px !important;
		font-weight: 700 !important;
		font-size: 15px !important;
		border-radius: 10px !important;
		margin: 0 auto !important;
	}

	&__overlay {
		position: absolute;
		top: 100px;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
