.relationship-content {
	display: flex;
	justify-content: center;
	&__header_text_section {
		display: flex;
		justify-content: center;
		padding: 14px;
		gap: 10px;
	}
}
