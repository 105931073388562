@import '../../../../semantic-ui/variables.less';

.relationship-setting-view {
	margin: 0 !important;
	padding: 20px 0 !important;

	&__header_text_section {
		display: flex;
		justify-content: center;
  		padding: 14px;
		gap: 10px;
	}

	&__loader {
		position: relative;
		height: 110px;
	}

	&__row {
		margin: 0 !important;
		align-items: center !important;
		border-bottom: 1px solid @border-color;

		&:last-child {
			padding-bottom: 0 !important;
			border-bottom: none;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;
		text-align: end;

		&:first-child {
			display: flex !important;
			justify-content: flex-end;
			align-items: center;
		}

		&-with-button {
			display: inline-flex;
			align-items: center;
			width: 100%;

			& img {
				padding-left: 10px;
				cursor: pointer;
			}
		}

		&_with-select {
			max-width: 355px;
		}
	}

	&__form__row {
		display: flex;
		justify-content: center;
		gap: 10px;

		& .create-setting-form__header-text {
			margin-bottom: 0;
		}
	}

	&__field {
		width: 80%;
	}

	&__text {
		text-align: center;
		font-family: @body-1-regular-family;
		font-size: 17px;
		font-weight: bold;
		margin-bottom: 28px;

		&_block {
			width: 100%;
			display: block;
			text-align: center;
		}
	}
	&__label {
		width: 190px;
	}

	&__table {
		margin: 0 !important;
		padding: 20px 0 !important;
	}
}
