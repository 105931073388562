@import '../../semantic-ui/variables.less';

.header {
	width: 100%;
	height: 100px;
	border-bottom: 1px solid @border-color;
	display: flex;
	justify-content: center;

	&__content {
		max-width: 1600px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px 0 10px;
	}

	&__search-field {
		width: 330px;

		.form-select__menu{
			width: 100%;
			max-height: 500px;
		}
	}

	&__side {
		display: flex;
		align-items: center;

		&_left {
			display: flex;
			gap: 24px;
		}
	}

	&__link-list {
		display: flex;
		gap: 24px;
		align-items: center;
	}

	&__link-item {
		list-style-type: none;
	}

	&__link {
		cursor: pointer;
		color: @dark-80 !important;
		font-size: 14px;
		font-weight: 600;
		text-decoration: none !important;

		&:hover {
			color: @primary-500;
		}
	}
}
