@import '../../../../../semantic-ui/variables.less';

.todo-component {
	max-width: 100%;
	min-width: 695px;
	height: 100%;
	background-color: @white-500;
	border: 1px solid @border-color;
	border-radius: 12px;
	position: relative;

	&__nav {
		position: absolute;
		top: -44px;
		display: flex;
		height: 44px;
		margin-left: 10px;
		margin-right: 10px;

		&-item {
			padding: 10px 24px;
			cursor: pointer;
			color: @dark-80;
			font-size: 13px;
			border: 1px solid @border-color;
			border-bottom: none;
			border-radius: 4px 4px 0 0;
			line-height: 1.2;
			font-weight: 600;
			background-color: @pageBackground;
			display: flex;
			align-items: center;

			&_active {
				background-color: @white-500;
			}
		}
	}
	.todo-component__nav-badge {
		border-radius: 4px;
		font-size: 9px;
		font-weight: 700;
		color: @white-500;
		padding: 4px 6px;
		line-height: 11px;
		margin-left: 10px;
	}

	&__header {
		height: 53px;
		border-bottom: 1px solid @border-color;
		color: @dark-500;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 40px;

		&-title {
			font-weight: 600;
			font-size: 17px;
		}

		&-checkbox {
			padding-top: 2px;
			display: flex;
			gap: 10px;
			align-items: center;

			&-title {
				font-weight: 400;
				font-size: 13px;
			}
		}
	}

	&__empty-message-text {
		width: auto;
		background: @gray-10;
		height: 50px;
		text-align: center;
		vertical-align: middle;
		padding-top: 15px;
		border-radius: 5px;
		background: @gray-10;
		position: relative;
		display: block;
		margin: 40px;
		font-size: 13px;
	}

	&__info-list {
		list-style: none;
		padding: 4px 40px 4px 40px;
		position: relative;
		min-height: 72px;

		&-item {
			min-height: 72px;
			border-bottom: 1px solid @border-color;
			display: grid;
			grid-template-columns: 10.7fr 1fr 156px;
			gap: 40px;
			align-items: center;
		}

		&-item:last-child {
			border-bottom: none;
		}

		&-text {
			color: @dark-500;
			font-size: 13px;
			font-weight: 400;
			line-height: 20px;

			&_accent {
				color: @primary-500;
				font-weight: 600;
				line-height: 32px;
				white-space: nowrap;
			}
		}

		&-link {
			text-decoration: none !important;
		}

		&-button {
			border: 1px solid;
			border-radius: 12px !important;
			font-size: 13px;
			padding: 8px 20px;
			color: @dark-500 !important;
			line-height: 1.5;
			cursor: pointer;
			font-weight: 500;
			transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out,
				border-color 0.15s ease-in-out;
			text-align: center;

			&_primary {
				border-color: @color-option-1;

				&:hover {
					color: @white-500 !important;
					background-color: @color-option-1;
				}
			}

			&_success {
				border-color: @color-option-1;

				&:hover {
					color: @white-500 !important;
					background-color: @color-option-1;
				}
			}

			&_secondary {
				border-color: @gray-500;

				&:hover {
					color: @white-500 !important;
					background-color: @gray-500;
				}
			}

			&_warning {
				border-color: @color-option-3;

				&:hover {
					color: @white-500 !important;
					background-color: @color-option-3;
				}
			}

			&_disabled {
				cursor: default;
				pointer-events: none;
				filter: opacity(0.5);
			}
		}
	}
}

@media screen and (max-width: 1630px) {
	.todo-component {
		&__info-list {
			&-item {
				gap: 20px;
			}
		}
	}
}

@media screen and (max-width: 1330px) {
	.todo-component {
		&__header {
			padding-left: 30px;
		}

		&__info-list {
			padding: 4px 26px 43px 30px;
		}
	}
}
