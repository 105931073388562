@import '../../semantic-ui/variables.less';

.table-container {
    background: @white-500;
    border-radius: 12px;
    border: 1px solid @border-color;
    min-height: 100px;

    &__without-header {
        font-size: 13px;
        border: none !important;
        border-radius: 0 !important;
    }

    &__header {
        padding: 0 32px 0 32px;
        border-bottom: 1px solid @border-color;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            color: @dark-500;
            font-size: 17px;
        }

        &-left-side {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        &-right-side {
            border-left: 1px solid @border-color;
            position: relative;
            width: 260px;
            height: 55px;


            &-search {
                height: 55px;
                width: 100%;
                position: absolute;
                background-color: @white-500;
                display: flex;

                & .input {
                    width: 100%;

                    & input {
                        border: none !important;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
            }
        }

        &-buttons {
            display: flex;
            width: 55px;
            flex-direction: row;
            justify-content: space-between;
        }

        &-button {
            width: 24px;
            height: 24px;
            cursor: pointer;
            background-image: url('../images/add-button.svg');
            transition: background-image .15s ease-in-out;
            background-repeat: no-repeat;
            
            &:hover {
                background-image: url('../images/add-button-hover.svg');
            }
        }

        &-download-button {
            width: 24px;
            height: 24px;
            margin-top: 1px;
            cursor: pointer;
            background-image: url('../images/download-button.svg');
            transition: background-image .15s ease-in-out;
            background-repeat: no-repeat;

            &:hover {
                background-image: url('../images/download-button-hover.svg');
            }
        }
    }

    &__table {
        display: block;
        padding-bottom: 20px;

        .ant-table-wrapper table {
            border-radius: 0;
        }

        .ant-table-wrapper table td {
            border-color: @border-color;
        }

        .ant-table-wrapper .ant-table .ant-table-header {
            border-radius: 0;
        }

        .ant-table-container, .ant-table-header {
            border-start-start-radius: 0;
            border-start-end-radius: 0;
        }

        .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
            border-start-start-radius: 0;
        }

        .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
            border-start-end-radius: 0;
        }

        .ant-table-wrapper .ant-table {
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
        }

        .ant-badge-status-text {
            font-family: "Montserrat", sans-serif;
            font-size: 13px !important;
        }

        .ant-table-placeholder td {
            border-bottom: none!important;
        }

        .ant-table-cell.bold {
            font-weight: bold;
            color: rgba(16, 21, 19, 0.8);
        }
    }

    &__table:has(div.table-pagination){
        padding-bottom: 0;
    }

    &_info {
        .ant-table-wrapper .ant-table-container table:first-child>tbody>tr:not(.ant-table-placeholder)>td:first-child {
            padding-left: 32px!important;
        }
        .ant-table-wrapper .ant-table-container table:first-child>tbody>tr:not(.ant-table-placeholder)>td:last-child {
            padding-right: 32px!important;
        }

        .ant-table-wrapper .ant-table-container table:first-child>tbody>tr:not(.ant-table-placeholder)>th:first-child {
            padding-left: 32px!important;
        }
        .ant-table-wrapper .ant-table-container table:first-child>tbody>tr:not(.ant-table-placeholder)>th:last-child {
            padding-right: 32px!important;
        }

        .ant-table-wrapper .ant-table-container table:first-child>thead>tr:not(.ant-table-placeholder)>td:first-child {
            padding-left: 32px!important;
        }
        .ant-table-wrapper .ant-table-container table:first-child>thead>tr:not(.ant-table-placeholder)>td:last-child {
            padding-right: 32px!important;
        }

        .ant-table-wrapper .ant-table-container table:first-child>thead>tr:not(.ant-table-placeholder)>th:first-child {
            padding-left: 32px!important;
        }
        .ant-table-wrapper .ant-table-container table:first-child>thead>tr:not(.ant-table-placeholder)>th:last-child {
            padding-right: 32px!important;
        }
    }

    &_children {
        border-radius: 0 !important;
        border: none !important;

        .table-container__table {
            padding-bottom: 0;
        }

        .ant-table-wrapper .ant-table-container table:first-child>tbody>tr:last-child > td {
            border-bottom: none;
        }

        &_with-border {
            .ant-table-wrapper .ant-table-container table:first-child>tbody>tr:last-child > td {
                border-bottom: 1px solid @border-color;
            }
        }
    }
}
