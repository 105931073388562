@import '../../../../semantic-ui/variables.less';

.modal-header {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__actions {
		display: flex;
		align-items: center;
	}

	&__img {
		width: 24px;
		margin-top: 8px;
		margin-left: -9px;
		margin-right: 23px;
	}

	&__search {
		height: 54px;
		width: 100%;
		display: flex;
		border-left: 1px solid @border-color;
		border-right: 1px solid @border-color;

		& .input {
			width: 100%;

			& input {
				border: none !important;
				font-size: 13px;
				font-weight: 500;
			}
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
	}

	&__header-button {
		width: 24px;
		height: 24px;
		cursor: pointer;
		margin-right: 32px;
		background-image: url('../../../../components/images/add-button.svg');
		transition: background-image .15s ease-in-out;

		&:hover {
			background-image: url('../../../../components/images/add-button-hover.svg');
		}
	}

	&__download-button {
		width: 24px;
		height: 24px;
		cursor: pointer;
		margin-top: 2px;
		margin-right: 5px;
		background-image: url('../../../../components/images/download-button.svg');
		transition: background-image .15s ease-in-out;
		background-repeat: no-repeat;

		&:hover {
			background-image: url('../../../../components/images/download-button-hover.svg');
		}
	}

	&__close-button {
		width: 40px;
	}
}
