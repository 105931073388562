@import '../../../../semantic-ui/variables.less';

.right-header-content {
	display: flex;
	gap: 10px;
	height: 48px;
	position: relative;
	align-items: center;

	&__container {
		border-radius: 10px;
		padding: 12px;
		background-color: @gray-10;
		display: flex;
		align-items: center;
		gap: 6px;
		cursor: pointer;
		position: relative;
	}

	&__badge {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		right: 5px;
		background-color: @secondary-500;

		&_with-number {
			color: @white-500;
			width: 13px;
			height: 13px;
			font-size: 7px;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 7px;
			right: 7px;
			padding-top: 1px;
		}
	}

	&__profile {
		position: absolute;
		min-width: 288px;
		border-radius: 12px;
		border-color: @border-color;
		background-color: @white-500;
		padding: 34px 0 24px 0;
		top: 58px;
		right: 0;
		box-shadow: @shadow_modal;
		z-index: 22;

		&-user {
			padding: 0 24px;

			&-name {
				font-size: 16px;
				font-weight: 700;
			}

			&-email {
				color: @gray-80;
				font-size: 14px;
				font-weight: 400;
			}
		}

		&-nav {
			padding-top: 10px;
			font-size: 13px;

			&-item {
				color: @dark-80;
				padding: 12px 24px;
				display: flex;
				gap: 10px;
				align-items: center;
				cursor: pointer;
				line-height: 1;

				&:not(:last-child) {
					border-bottom: 1px solid @border-color;
				}

				&:hover {
					background-color: @gray-10;
				}

				& img {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
}
