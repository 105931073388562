@import '../../../../semantic-ui/variables.less';

.document-component {
	&__row {
		margin: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 14px !important;
		align-items: center;

		&:last-child {
			padding-bottom: 0 !important;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;

		&:first-child {
			display: flex !important;
			justify-content: flex-end;
			align-items: center;
		}

		&-with-button {
			width: 100%;

			& img {
				padding-left: 10px;
				cursor: pointer;
			}
		}

		&_with-select {
			max-width: 355px;
		}
	}

	&__form__row {
		display: flex;
		justify-content: center;
		gap: 10px;

		& .create-setting-form__header-text {
			margin-bottom: 0;
		}
	}

	&__field {
		width: 80%;
	}
	&__close {
		position: relative;
		top: 5px;
		right: 20px;
		width: 24px;
		height: 24px;
		background: url('../../../../components/images/close.svg') no-repeat;
		background-size: 100%;
		cursor: pointer;

		&:hover {
			width: 24px;
			height: 24px;
			background: url('../../../../components/images/close-hover.svg') no-repeat;
			background-size: 100%;
		}
	}
	&__button {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
		margin-left: auto;
	}

	&__button-row {
		display: flex;
		justify-content: flex-end;
		margin: auto;
		width: 740px;
		margin-bottom: 20px;
		margin-top: 5px;

		&-text {
			font-size: 13px;
			margin-top: 2px;
		}

		img {
			cursor: pointer;
			margin-left: 10px;
			margin-right: 3px;
		}
	}

	&__text {
		text-align: center;
		font-family: @header-5-bold-family;
		font-weight: @header-5-bold-weight;
		font-size: 14px;
		padding: 14px;

		&_block {
			width: 100%;
			display: block;
			text-align: center;
		}
	}
}
