@import '../../../../semantic-ui/variables.less';

.user-role-form {
    padding: 15px 0 0 0;
    overflow: auto;

	.table-container {
		&__table{
			table tbody tr:last-child td {
				border-bottom: none;
			}
		}
	}

    &__field {
        width: 435px;

        &-wrapper {
            display: flex;
            justify-content: center;
            padding-bottom: 15px;
        }
    }
}