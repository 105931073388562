@import '../../../../../../semantic-ui/variables.less';

.transports-filter {
	position: sticky;
	background-color: white;
	border: 1px solid @border-color;
	text-align: left;
	display: flex;
	flex-direction: column;
	margin: auto;
	vertical-align: middle;
	justify-content: center;
	padding: 5px;
}
.my-custom-filter-title {
	color: @dark-500;
	font-size: 17px;
	font-weight: 400;
}
