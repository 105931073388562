@import '../../../../../semantic-ui/variables.less';

.create-setting-form {
	margin: 0 !important;
	padding: 20px 0 !important;

	&__loader {
		position: relative;
		height: 110px;
	}

	&__row {
		margin: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 14px !important;
		align-items: center !important;

		&:last-child {
			padding-bottom: 0 !important;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;
		text-align: end;

		&:first-child {
			display: flex !important;
			justify-content: flex-end;
			align-items: center;
		}

		&-with-button {
			display: inline-flex;
			align-items: center;
			width: 100%;

			& img {
				padding-left: 10px;
				cursor: pointer;
			}
		}

		&_with-select {
			max-width: 355px;
		}
	}

	&__form__row {
		display: flex;
		justify-content: center;
		gap: 10px;

		& .create-setting-form__header-text {
			margin-bottom: 0;
		}
	}
	
	&__field {
		width: 80%;
	}

	&__text {
		text-align: center;
		font-family: @header-5-bold-family;
		font-weight: @header-5-bold-weight;
		font-size: 14px;
		padding: 14px;

		&_block {
			width: 100%;
			display: block;
			text-align: center;
		}
	}
	&__label {
		width: 190px;
	}
}
