.info-icon {
    width: 20px;
    height: 20px;
    background: url("../icons/info-inactive-icon.svg") no-repeat;
    background-size: 100%;
    cursor: pointer;

    &:hover {
        width: 20px;
        height: 20px;
        background: url("../icons/info-icon.svg") no-repeat;
        background-size: 100%;
        cursor: pointer;
    }
}