@import '../../../../../semantic-ui/variables.less';

.custom-accreditation-form {
	.table-container {
		&__table{
			padding-bottom: 0;
			border-bottom: 1px solid @border-color;

			table tbody tr:last-child td {
				border-bottom: none;
			}
		}
	}

	&__loader {
		position: relative;
		height: 110px;
	}

	&__toggle {
		display: flex;

		margin-top: 16px;
		margin-bottom: 16px;
		margin-left: 30px;
		&__checkbox {
			margin-left: 10px;
		}
	}
}
