@import '../../../../../semantic-ui/variables.less';

.accounts-info {
	width: 422px;
	background-color: @white-500;
	border: 1px solid @border-color;
	border-radius: 12px;

	&__header {
		height: 53px;
		border-bottom: 1px solid @border-color;
		color: @dark-500;
		font-size: 17px;
		font-weight: 600;
		line-height: 1;
		display: flex;
		align-items: center;
		padding-left: 40px;
	}

	&__info-list {
		list-style: none;
		padding: 4px 40px 4px 40px;
		position: relative;
		min-height: 82px;

		&-item {
			min-height: 82px;
			border-bottom: 1px solid @border-color;
			display: grid;
			grid-template-columns: 5fr 1fr;
			gap: 40px;
			align-items: center;
		}

		&-item:last-child {
			border-bottom: none;
		}

		&-text {
			color: @dark-500;
			font-size: 13px;
			font-weight: 400;
			line-height: 20px;

			&_link {
				color: @dark-500 !important;
				text-decoration: none !important;
			}

			&_accent {
				color: @primary-500;
				font-weight: 600;
				line-height: 32px;
				white-space: nowrap;
			}
		}
	}
}

@media screen and (max-width: 1630px) {
	.accounts-info {
		width: 380px;

		&__info-list {
			&-item {
				gap: 20px;
			}
		}
	}
}

@media screen and (max-width: 1330px) {
	.accounts-info {
		width: 360px;

		&__header {
			padding-left: 30px;
		}

		&__info-list {
			padding: 4px 26px 43px 30px;
		}
	}
}
