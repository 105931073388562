@import '../../../semantic-ui/variables.less';

.alert-modal {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__img {
		width: 76px;
		height: 76px;
		margin: 35px 0 9px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		padding-left: 1px;

		&_warning {
			border: 3px solid @warningIconColor;
			animation: animate-warning-icon 0.5s;
		}

		&_error {
			padding-top: 19px;
			border: 3px solid @errorIconColor;
			animation: animate-warning-icon 0.5s;
		}
	}

	&__icon-wrapper {
		width: 32px;
		height: 32px;
		animation: animate-error-icon 0.5s;
	}

	&__icon {
		font-size: 52px;

		&_warning {
			color: @warningIconColor;
			animation: animate-i 0.5s;
		}

		&_left {
			transform: rotate(-45deg) translate(13px, 7px);
		}

		&_right {
			transform: rotate(45deg) translate(4px, -10px);
		}

		&_error {
			height: 42px;
			width: 4px;
			display: inline-block;
			border-radius: 5px;
			background-color: @errorIconColor;
		}
	}

	&__title {
		display: block;
		padding: 15px 18px 0;
		font-size: 17px;
		font-weight: 600;
		font-family: @header-2-bold-family;
		text-align: center;
	}

	&__text {
		display: block;
		margin: 5px 0;
		font-size: 14px;
		font-weight: 400;
		font-family: @body-1-regular-family;
		text-align: center;
	}

	&__buttons-wrapper {
		display: flex;
		justify-content: center;
		gap: 10px;
		margin: 17px 0;
	}

	&__button {
		height: 41px;
		padding: 9px 15px;
		border: none;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 500;
		font-family: @body-1-regular-family;
		cursor: pointer;
		color: @white-500;

		&_success {
			background-color: @primary-500;

			&:focus {
				box-shadow: 0 0 0 3px @buttonOutlineColor;
				outline: none;
			}
		}

		&_error {
			background-color: @errorButtonColor;
		}
	}
}

@keyframes animate-warning-icon {
	0% {
		transform: rotateX(100deg);
		opacity: 0;
	}

	100% {
		transform: rotateX(0);
		opacity: 1;
	}
}

@keyframes animate-i {
	0% {
		transform: rotate(45deg);
		opacity: 0;
	}

	25% {
		transform: rotate(-25deg);
		opacity: 0.4;
	}

	50% {
		transform: rotate(15deg);
		opacity: 0.8;
	}

	75% {
		transform: rotate(-5deg);
		opacity: 1;
	}

	100% {
		transform: rotateX(0);
		opacity: 1;
	}
}

@keyframes animate-error-icon {
	0% {
		margin-top: 1.625em;
		transform: scale(0.4);
		opacity: 0;
	}
	50% {
		margin-top: 1.625em;
		transform: scale(0.4);
		opacity: 0;
	}
	80% {
		margin-top: -0.375em;
		transform: scale(1.15);
	}
	100% {
		margin-top: 0;
		transform: scale(1);
		opacity: 1;
	}
}
