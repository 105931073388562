@import '../../../../../semantic-ui/variables.less';

.requests-info {
	width: 422px;
	background-color: @white-500;
	border: 1px solid @border-color;
	border-radius: 12px;
	position: relative;
	margin-top: 46px;

	&__header {
		height: 53px;
		border-bottom: 1px solid @border-color;
		color: @dark-500;
		font-size: 17px;
		font-weight: 600;
		line-height: 1;
		display: flex;
		align-items: center;
		padding-left: 40px;
	}

	&__row {
		margin: 32px 40px;
		display: flex;
		align-items: center;
		gap: 25px;

		&-cell {
			min-width: 98px;
			height: 98px;
			box-sizing: border-box;
			border: 1px solid @border-color;
			border-radius: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 38px;
			font-weight: 300;
			line-height: 41.69px;

			&_green {
				color: @color-option-1;
			}

			&_blue {
				color: @primary-500;
			}

			&_gray {
				color: @gray-500;
			}
		}

		&-text {
			color: @dark-80;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		}
	}
}

@media screen and (max-width: 1630px) {
	.requests-info {
		width: 380px;
	}
}

@media screen and (max-width: 1330px) {
	.requests-info {
		width: 360px;

		&__header {
			padding-left: 30px;
		}

		&__row {
			margin: 32px 30px;
		}
	}
}
