@import '../../../../../semantic-ui/variables.less';

.notifications-list {
	width: 370px;
	min-height: 85px;
	max-height: 500px;
	position: absolute;
	top: 58px;
	right: 84px;
	border-radius: 12px;
	border-color: @border-color;
	background-color: @white-500;
	box-shadow: @shadow_modal;
	text-align: center;
	z-index: 22;
	overflow-y: auto;

	&__empty-list-message {
		width: 100%;
		padding: 40px;
		border-radius: 12px;
		background-color: @white-500;
		text-align: center;
	}

	&__item {
		display: flex;
		align-items: flex-start;
		width: 100%;
		padding: 18px 24px;
		background: @white-500;
		padding: 18px 24px;
		border-bottom: 1px solid @border-color;

		&:first-child {
			margin-top: 0;
			border-top-right-radius: 12px;
			border-top-left-radius: 12px;
		}

		&:last-child {
			border-bottom-right-radius: 12px;
			border-bottom-left-radius: 12px;
			border-bottom: none;
		}
	}

	&__content {
		flex: 1;
	}

	&__content-header {
		display: flex;
		align-items: center;
		height: 32px;
	}

	&__status {
		width: 11px;
		height: 11px;
		border-radius: 50%;
		margin-right: 7px;

		&_info {
			background: #17a2b8;
		}

		&_warning {
			background: @color-option-4;
		}

		&_danger {
			background: @color-option-3;
		}

		&_success {
			background: @color-option-1;
		}
	}

	&__link {
		font-weight: bolder;
		margin: 0;
		text-decoration: underline;
		cursor: pointer;
		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__message {
		margin-left: 17px;
		font-size: 12px;
		text-align: left;
	}

	&__close-button {
		width: 40px;
	}
}

.notifications-list::-webkit-scrollbar-track {
	margin: 12px 0;
}
