@import '../../../../../semantic-ui/variables.less';

.relationship-form {
	margin: 0 !important;
	padding: 20px 0 !important;
	max-height: 511px;
	overflow-y: auto;

	&__row {
		margin: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 14px !important;

		&:last-child {
			padding-bottom: 0 !important;
		}
		&-text {
			display: flex;
			justify-content: center !important;
			font-weight: 500 !important;
			font-size: large !important;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;

		&:first-child {
			display: flex !important;
			justify-content: flex-end;
			align-items: center;
			text-align: end;
		}
	}
	&__contract-section {
		display: flex;
		align-items: center;
		gap: 10px;

		&-title {
			flex: 2;
		}

		&-date {
			flex: 1;
		}
	}
	.text-icon .input-group-text {
		width: 25px;
	}
}
