@import '../../../../semantic-ui/variables.less';

.chats-list {
	position: fixed;
	z-index: 22;
	right: 0;
	top: 53px;
	bottom: 53px;
	height: calc(100vh - 106px);
	width: 378px;
	min-width: 378px;
	background: @white-500;
	box-shadow: @shadow_modal;
	border-radius: 12px 0 0 12px;
	border-color: @border-color;

	&__empty-message {
		background: @gray-10;
		padding: 16px;
		text-align: center;
		margin: 15px;
		border-radius: 5px;
		position: absolute;
		top: calc(50% - 25px);
		width: calc(100% - 30px);
	}

	&__close-btn {
		position: absolute;
		left: -31px;
		top: 10px;
		width: 30px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: @white-500;
		cursor: pointer;
		border-radius: 12px 0 0 12px;
		box-shadow: @shadow_modal;
	}

	&__header {
		height: 53px;
		display: flex;
		align-items: center;
		padding: 10px 14px;
		border-bottom: 1px solid @border-color;
	}

	&__header-section {
		display: flex;
		gap: 10px;
		justify-content: space-between;
		align-items: center;
		font-size: 15px;
		font-weight: 600;
	}

	&__header-img {
		width: 20px;
		height: 20px;
		opacity: 0.7;
	}

	&__list {
		width: 100%;
		height: calc(100% - 53px);
		list-style: none;
		overflow-y: auto;
	}

	&__list-item {
		height: 71px;
		padding: 15px 5px 15px 14px;
		border-bottom: 1px solid @border-color;
		cursor: pointer;

		&:hover {
			background: @dark-5;
		}
	}

	&__list-item-header {
		display: flex;
		justify-content: space-between;
	}

	&__list-item-title {
		position: relative;
		display: flex;
		align-items: center;
		gap: 7px;
		font-size: 14px;
		font-weight: 500;
	}

	&__list-item-actions {
		display: flex;
		align-items: center;
		gap: 13px;
	}

	&__date {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 18px;
		padding: 0px 10px;
		border-radius: 4px;
		background-color: @dark-5;
		color: @dark-80;
		font-family: Montserrat;
		font-size: 10px;
		font-weight: 500;
	}

	&__close-button {
		width: 39px;
		height: 39px;
		background: url('../../../../components/images/close.svg') no-repeat;
		background-size: 100%;
		cursor: pointer;

		&:hover {
			width: 39px;
			height: 39px;
			background: url('../../../../components/images/close-hover.svg') no-repeat;
			background-size: 100%;
		}
	}

	&__list-item-text {
		display: block;
		width: 100%;
		padding: 5px 40px 0 29px;
		font-size: 12px;
		font-weight: 400;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		opacity: 0.7;
	}

	&__badge {
		position: absolute;
		top: -3px;
		left: 15px;
		width: 12px;
		height: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: @secondary-500;
		color: @white-500;
		font-size: 7px;
		font-weight: 700;
		z-index: 2;
	}
}
