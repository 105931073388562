@import '../../../../../semantic-ui/variables.less';

.req-form {
	display: flex;
	flex-direction: column;
	padding-bottom: 14px;
	max-height: 511px;
	overflow-y: auto;

	&__section {
		padding-bottom: 21px;
		border-bottom: 1px solid @border-color;
		padding-top: 14px;

		&:last-child {
			border-bottom: none;
		}
	}

	&__header-text {
		text-align: center;
		font-family: @body-1-regular-family;
		font-size: 17px;
		font-weight: 500;
		margin-bottom: 28px;

		&_block {
			width: 100%;
			display: block;
			text-align: center;
		}
	}

	&__row {
		display: flex;
		margin-bottom: 14px;

		&:last-child {
			margin-bottom: 0;
		}

		&_double {
			flex-direction: column;
			gap: 10px;
		}
	}

	.features {
		width: 70%;
		margin-left: 20%;
	}

	&__text {
		width: 40%;
		max-width: 292px;
		font-family: @body-1-regular-family;
		font-size: 13px;
		font-weight: 400;
		padding: 6px 10px;

		&_right {
			text-align: right;
		}

		&_bold {
			font-weight: bolder;
		}

		&_compact {
			text-align: right;
			line-height: 1.2;
			max-width: 86px !important;
			padding: 0 !important;
		}
		&_tax {
			font-size: 10px;
			line-height: 12px;
			font-weight: 400;
			display: flex;
			justify-content: center;
			color: darkgray;
			position: relative;
			bottom: 10px;
			right: 120px;
		}
	}

	&__field {
		width: 60%;
		max-width: 437px;
		padding: 0 10px;

		&_medium {
			width: 168px;
		}

		&_with-button {
			display: flex;
		}

		&_without-button {
			width: 400px !important;
		}

		&_with-time {
			width: 98px !important;

			& input[type='time']::-webkit-calendar-picker-indicator {
				display: none !important;
				-webkit-appearance: none;
			}

			& input[type='time']::-webkit-datetime-edit {
				text-align: center;
			}
		}

		&_with-checkbox {
			display: flex;
			justify-content: space-between;
		}
	}

	&__field-wrapper {
		width: 100%;
	}

	&__date-field {
		max-width: 250px;

		&_with-time {
			max-width: 410px !important;
			display: flex;
		}
	}

	&__add-button {
		width: 26px;
		height: 24px;
		margin-left: 12px;
		margin-top: 2px;
		cursor: pointer;
		background-image: url('../../../../../components/images/add-button.svg');
		transition: background-image 0.15s ease-in-out;

		&:hover {
			background-image: url('../../../../../components/images/add-button-hover.svg');
		}
	}

	&__checkbox {
		display: flex;
		align-items: center;
		margin-left: 10px;
	}

	&__checkbox-wrapper {
		display: flex;
	}

	&__label,
	&__checkbox-label {
		width: 40%;
		font-family: @body-1-regular-family;
		font-size: 13px;
		padding: 6px 10px;
		font-weight: bolder;
		white-space: nowrap;
	}

	&__checkbox-label {
		padding-right: 0;
	}

	&__time-fields {
		display: flex;
		flex-direction: column;
		gap: 5px;

		&-wrapper {
			display: flex;
		}

		&-separator {
			line-height: 2;
		}
	}
}
